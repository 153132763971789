import React from 'react'

const defaultState = {
  open: false,
}

const MenuContext = React.createContext(defaultState)

class MenuProvider extends React.Component {
  state = defaultState

  toggleMenu = () => {
    this.setState({ open: !this.state.open })
  }

  closeMenu = () => {
    setTimeout(() => this.setState({ open: false }), 100)
  }

  render () {
    const { children } = this.props
    const { open } = this.state
    return (
      <MenuContext.Provider
        value={{
          open: open,
          toggleMenu: this.toggleMenu,
          closeMenu: this.closeMenu,
        }}
      >
        {children}
      </MenuContext.Provider>
    )
  }
}

export default MenuContext

export { MenuProvider }
