/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/app.scss'

import React from 'react'
import { MenuProvider } from './src/context/MenuContext'
export const wrapRootElement = ({ element }) => (
  <MenuProvider>{element}</MenuProvider>
)
